import React from "react";

const Error = () => {
	return (
		<div>
			<h1>Error</h1>
		</div>
	);
};

export default Error;
